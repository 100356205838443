import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import VideoPlaylist from './pages/videoplaylist'
import Home from './pages/home'

ReactGA.initialize('UA-181118718-1')

function RouterComponent({ location }) {
	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location])

	return (		
		<Switch>
			<Route path="/home">
				<Home />
			</Route>

			<Route path="/:slug/:id/:settings">
				<VideoPlaylist />
			</Route>
			<Route path="/:slug/:id">
				<VideoPlaylist />
			</Route>
			<Route path="/:slug">
				<VideoPlaylist />
			</Route>

			<Redirect to="/home" />
			
		</Switch>
	)
}

export default withRouter(RouterComponent)
