import React, { useState, useEffect, Fragment } from 'react'
import Helmet from 'react-helmet'
import Select from 'react-select'
import ReactPlayer from 'react-player'
import { WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon } from 'react-share'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Switch from '@material-ui/core/Switch'
import LinearProgress from '@material-ui/core/LinearProgress'
import { firestore } from '../data/firebase'
import { JSONPlayLists } from '../data/playlists'
import { Playlist } from '../components/playlist'
import { set } from 'react-ga';

let videoVolume = 0.5

let backColor1 = '#f0f0f0'
let backColor2 = '#e5e5e5'
let textColor = '#000000'

const MainAppView = styled.div`max-width:640px; overflow:auto; margin-left: auto; margin-right: auto; font-family: Nunito; height: 99vh; border-radius: 4px;`
const TitleSpan = styled.span`font-size:16px; font-weight: bold; vertical-align: top; word-wrap: break-word; width: 60%; float:left;`
const HomeLink = styled.div`float:left; padding-top: 4px; font-size: 20px;`
const HeaderDiv = styled.div`margin-bottom:3px; width: 99%; display: inline-block;`
const PlaylistSelect = styled(Select)`z-index: 10; color: black; float: left; width: 70%;`
const VideoSelect = styled(Select)`text-align: center; color: navy; margin-left: 10px; float: right; width: 100%;`
const LogoImg = styled.img`max-width: 35%; float:right; max-height: 40px; text-decoration: none;`

const LogoInText = styled.img`float:left; height: 30px; margin-left: 3px; text-decoration: none;`

const VideoTitle = styled.div`font-size: 18px; font-weight: bold; color: #6495ED; background-color: white; border-radius: 4px; width: 94vw; max-width: 630px; margin-bottom: 3px; min-height: 26px;`
const PlayerDiv = styled.div`float: left; font-size: 14px; font-weight: bold; vertical-align: top; margin-left: auto; margin-right: auto; text-align:center; align: center; `
const VideoPlayer = styled(ReactPlayer)`max-height: 240px; border-radius: 6px;`
const OutControlsDiv = styled.div`color: #6495ED; background-color: white; border-radius: 4px; width: 94vw; max-width: 630px; height: 32px; padding-left:5px; padding-right: 5px; margin-top:3px;`

const TabContent = styled.div`float: left; padding-left: 10px; font-size: 14px; overflow: auto; width:97%; height: calc(100vh - 420px);
								::-webkit-scrollbar { width: 18px; }
								::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3); border-radius: 6px; }
								::-webkit-scrollbar-thumb { border-radius: 6px; -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.9); }`
const TabNames = styled.div`width:100%;`
const TabButton = styled.span`display: inline-block; width: 50%; font-size: 20px; font-family: Nunito; border: 0px; height: 25px; text-align: center; color: #ffffff; font-weight: bold; cursor: pointer;`

const Clearfix = styled.div`clear: both;`

function VideoPlaylist(props) {	
	let { slug, id, settings } = useParams()
	
	const history = useHistory()

	let defaultLoopOnSame = false
	let defaultContinueNext = true
	if (settings === 'only') {
		defaultLoopOnSame = true
		defaultContinueNext = false
	}
	let defaultShuffle = false

	const [listError, setListError] = useState('')
	const [brandInfo, setBrandInfo] = useState({ brandLogo: '', brandOnlyLogo: '' })
	const [p, setP] = useState(null) // p = PlaylistData
	const [v, setV] = useState() // v = videoData
	const [loopOnSame, setLoopOnSame] = useState(defaultLoopOnSame)
	const [continueNext, setContinueNext] = useState(defaultContinueNext)
	const [shuffle, setShuffle] = useState(defaultShuffle)
	const [showTabs, setShowTabs] = useState({tab1: 'block', tab1ButtonBorder: 'solid 3px red', tab2: 'none', tab2ButtonBorder: 'dotted 1px gray'})
	
	
	let brandName = 'vidpoi'	
	const items = []
	
	let selectPlaylistOptions = []
	let selectedPlaylistOption = []

	let selectVideoItems = []
	let selectedVideoItems = []

	let repeatEnabled = true
	let continueEnabled = true
	let shuffleEnabled = true

	let reverseList = false

	let adEnabled = false
	let pageTitleAdditionalText = 'VIDPOI'
	
	const changeTabs = (activeTabName) => {
		if (activeTabName === 'tab1') {
			setShowTabs({tab1: 'block', tab1ButtonBorder: 'solid 3px red', tab2: 'none', tab2ButtonBorder: 'dotted 1px gray'}) 
		} else if (activeTabName === 'tab2') {
			setShowTabs({tab1: 'none', tab1ButtonBorder: 'dotted 1px gray', tab2: 'block', tab2ButtonBorder: 'solid 3px red'})
		}
	}
	
	useEffect(() => {
		let playlistsRef = firestore.collection('playlists')
		playlistsRef.where("slug", "==", slug).get().then(slugsnapshot => {
			if (slugsnapshot.docs.length === 1) {
				const activePlaylist = slugsnapshot.docs[0].data()

				let videosLocal = activePlaylist.videos
							
				defaultShuffle = activePlaylist.shuffle ? activePlaylist.shuffle : true
				setShuffle(defaultShuffle)
	
				brandName = activePlaylist.brand ? activePlaylist.brand : 'vidpoi'
				backColor1 = activePlaylist.backColor1 ? activePlaylist.backColor1 : 'rgb(100, 149, 237)'
				backColor2 = activePlaylist.backColor2 ? activePlaylist.backColor2 : 'rgb(100, 149, 237)'
				textColor =  activePlaylist.textColor ? activePlaylist.textColor : '#ffffff'
	
				repeatEnabled = activePlaylist.repeatEnabled ? activePlaylist.repeatEnabled: false
				continueEnabled = activePlaylist.continueEnabled ? activePlaylist.continueEnabled : true
				shuffleEnabled = activePlaylist.shuffleEnabled ? activePlaylist.shuffleEnabled : true
				adEnabled = activePlaylist.adEnabled ? activePlaylist.adEnabled : true
	
				reverseList = activePlaylist.reverseList ? activePlaylist.reverseList : false
							
				pageTitleAdditionalText = activePlaylist.pageTitleAdditionalText ? activePlaylist.pageTitleAdditionalText : 'VIDPOI'
	
				selectedPlaylistOption.push({
					value: activePlaylist.slug,
					label: activePlaylist.title,
				})
	
				if (!id) {
					id = 0
					history.push(`/${slug}/${Math.floor(Math.random() * videosLocal.length)}`)
				}
	
				for (let i = 0; i < videosLocal.length; i++) {
					if (settings !== 'only') {
						selectVideoItems.push({
							value: i,
							label: videosLocal[i].name,
						})
					}
				}
				if (activePlaylist.categories) {
					playlistsRef.where('categories', 'array-contains-any', activePlaylist.categories).get().then(snapshot => { 
						const relatedPlaylists = snapshot.docs.map(doc => doc.data());
						
						for (let i = 0; i < relatedPlaylists.length; i++) {					
							selectPlaylistOptions.push({
								value: relatedPlaylists[i].slug,
								label: relatedPlaylists[i].title,
							})					
						}
					})
				}		
	
				setP({
					videos: videosLocal,
					items: items,
					selectVideoItems: selectVideoItems,
					selectPlaylistOptions: selectPlaylistOptions,
					selectedPlaylistOption: selectedPlaylistOption,
					reverseList: reverseList
				})
	
				firestore.collection('brands').doc(brandName).get().then(snapshot => { 
					const brandInformation = snapshot.data();
					setBrandInfo(brandInformation)
				})
			} else {
				setListError('list-not-found')
			}
			
		})
	}, [slug])

	useEffect(() => {
		if (p && p.videos && id && p.videos[id]) {
			selectedVideoItems = []
			selectedVideoItems.push({
				value: id,
				label: p.videos[id].name,
			})
			setV({
				activeVideoID: parseInt(id),
				selectedVideoItems: selectedVideoItems
			})

			// let element = document.getElementById("selectedVideoItem")
			// if (element) {
			// 	element.scrollIntoView()
			// }
		}
	}, [id, p])

	const setNewVideo = (newVideoID) => {
		history.push(`/${slug}/${newVideoID}`)
	}

	const getNextVideo = async () => {
		let nextVideoID = v.activeVideoID
		if (continueNext === true) {
			if(shuffle === true) {
				nextVideoID = Math.floor(Math.random() * p.videos.length)
			} else {
				nextVideoID = parseInt(v.activeVideoID) === (p.videos.length - 1) ? 0 : (parseInt(v.activeVideoID) + 1)
			}
			history.push(`/${slug}/${nextVideoID}`)
		}
	}

	return (
			<Fragment>
			{
				listError !== 'list-not-found' ?
					p && p.videos && v && p.videos[v.activeVideoID] ?
						<MainAppView style={{border: `4px solid ${backColor2}`, backgroundImage: backColor1, backgroundColor: backColor1}}>
							<Helmet>
								<title>{ p.videos[v.activeVideoID].name + ' - ' + pageTitleAdditionalText }</title>
								<meta name="description" content={ p.videos[v.activeVideoID].name + ' - ' + pageTitleAdditionalText } />
								<meta itemprop="name" content={ p.videos[v.activeVideoID].name + ' - ' + pageTitleAdditionalText } />
								<meta itemprop="description" content={ p.videos[v.activeVideoID].name + ' - ' + pageTitleAdditionalText } />
								
								<meta itemprop="og:title" content={ p.videos[v.activeVideoID].name + ' - ' + pageTitleAdditionalText } />
								<meta property="og:url" content={ 'https://vidpoi.com/' + slug +  '/' + id } />
								<meta property="og:type" content="video.movie" />
								<meta property="og:title" content={ p.videos[v.activeVideoID].name + ' - ' + pageTitleAdditionalText } />
								<meta property="og:description" content={ p.videos[v.activeVideoID].name + ' - ' + pageTitleAdditionalText } />
								{
									p.videos[v.activeVideoID].url.includes("youtube.com/watch") ?
										<meta property="og:image" content={ p.videos[v.activeVideoID].url.replace('https://www.youtube.com/watch?v=', 'https://i.ytimg.com/vi/') + '/hqdefault.jpg' } />
									: null
								}
							</Helmet>

							<ReactTooltip place="top" type="info" effect="float" multiline={true} border={true} id="settingsTooltip"
								getContent={ (dataTip) => dataTip ? <div><b style={{fontSize: 16}}>{dataTip.split('#')[0]}</b><div>{dataTip.split('#')[1]}</div></div> : null }
							/>
							<HeaderDiv>
								{
									settings !== 'only' ?
										<TitleSpan style={{color: textColor }}>
											<HomeLink>
												<LogoInText src={brandInfo.onlyLogo} />
												&nbsp;>&nbsp;
											</HomeLink>
											<PlaylistSelect defaultValue={p.selectedPlaylistOption} isSearchable="true" name="playlistSelect" options={p.selectPlaylistOptions} onChange={ (newSelectedOption) => history.push(`/${newSelectedOption.value}`)} />
										</TitleSpan>
									: null
								}
								<LogoImg src={brandInfo.logo} />
							</HeaderDiv>
							<PlayerDiv>
								<VideoTitle>
									{/* {videos[activeVideoID].name} <VideoTitleArtist>{videos[activeVideoID].artist ? '(' + videos[activeVideoID].artist + ')' : ''}</VideoTitleArtist> */}
									<div style={{float: 'left'}}>
										<FacebookShareButton style={{ margin: 1 }} url={String(window.location)} quote={p.videos[v.activeVideoID].name + ' - Watch with ' + brandName }>
											<FacebookIcon size={24} round={true} />
										</FacebookShareButton>
										<TwitterShareButton style={{ margin: 1 }} url={String(window.location)} title={p.videos[v.activeVideoID].name + ' - Watch with ' + brandName }>
											<TwitterIcon size={24} round={true} />
										</TwitterShareButton>
										<RedditShareButton style={{ margin: 1 }} url={String(window.location)} title={p.videos[v.activeVideoID].name + ' - Watch with ' + brandName }>
											<RedditIcon size={24} round={true} />
										</RedditShareButton>
										<WhatsappShareButton style={{ margin: 1 }} url={String(window.location)} title={p.videos[v.activeVideoID].name + ' - Watch with ' + brandName }>
											<WhatsappIcon size={24} round={true} />
										</WhatsappShareButton>
										<TelegramShareButton style={{ margin: 1 }} url={String(window.location)} title={p.videos[v.activeVideoID].name + ' - Watch with ' + brandName }>
											<TelegramIcon size={24} round={true} />
										</TelegramShareButton>
									</div>
									<div style={{float: 'right', width: '60%'}}>
										<VideoSelect defaultValue={v.selectedVideoItems} value={v.selectedVideoItems} isSearchable="true" name="videoSelect" options={p.selectVideoItems} onChange={ (newSelectedOption) => setNewVideo(newSelectedOption.value) } />
									</div>
									
									<Clearfix />
								</VideoTitle>				
								<VideoPlayer url={p.videos[v.activeVideoID].url + ''} controls={true} playing={true} loop={continueNext ? false: loopOnSame} width='100%' volume={videoVolume} onEnded={() => getNextVideo() } onVolumeChange={(e) => { videoVolume = e.target.volume } }  onError={(e) => { console.log('onError ', e, ' id:', v.activeVideoID); if(e === 150) { getNextVideo() } } } />
								{
									repeatEnabled || continueEnabled || shuffleEnabled ?
										<OutControlsDiv>
											{
												repeatEnabled ?
													<span style={{ float: 'left'}} data-tip={'Repeat#Repeat the same video'} data-for="settingsTooltip">
														Repeat
														<Switch checked={loopOnSame} onChange={() => { if(!loopOnSame) { setContinueNext(false) } setLoopOnSame(!loopOnSame) } } />
													</span>
												: null
											}
											{
												continueEnabled ?
													<span style={{float: 'left', display: (settings !== 'only') ? 'block' : 'none' }} data-tip={'Continue#Continue to the next video when finish'} data-for="settingsTooltip">
														Continue
														<Switch	checked={continueNext} onChange={() => { if(!continueNext) { setLoopOnSame(false) } setContinueNext(!continueNext) } }/>
													</span>
												: null
											}
											{
												shuffleEnabled ?
													<span style={{float: 'right', display: (settings !== 'only') ? 'block' : 'none' }} data-tip={'Shuffle#Continue to a random video'} data-for="settingsTooltip">
														Shuffle
														<Switch	checked={shuffle} onChange={() => { setShuffle(!shuffle) } } style={{display: (settings !== 'only') ? 'block' : 'none' }}  />
													</span>
												: null
											}
										</OutControlsDiv>
									: null					
								}
							</PlayerDiv>
							<div>							
								<TabContent style={{display: showTabs.tab1 }} activeVideoID={p.activeVideoID}>
									
									<Playlist activeVideoID={v.activeVideoID} slug={slug} videos={p.videos} textColor={textColor} settings={settings} reverseList={p.reverseList} />
								</TabContent>
								<TabContent style={{display: showTabs.tab2 }}>
									<h1 style={{color: 'white'}}>Comments</h1>
									<div style={{color: 'white'}}>There is no comment for this video yet.</div>
								</TabContent>
								<TabNames>
									<TabButton onClick={() => changeTabs('tab1')} style={{borderTop: showTabs.tab1ButtonBorder}}>List</TabButton>
									<TabButton onClick={() => changeTabs('tab2')} style={{borderTop: showTabs.tab2ButtonBorder}}>Comments</TabButton>
								</TabNames>
							</div>
						</MainAppView>
					:
						<div style={{ marginTop: '50vh'}}>
							<LinearProgress />
							<br />
							<LinearProgress color="secondary" />
						</div>
				:
					<div>LIST NOT FOUND</div>
			}
		</Fragment>
	)
}

export default VideoPlaylist
