import React from 'react'
import styled from 'styled-components'

const MainAppView = styled.div`max-width:640px; border: 4px solid #6495ED; overflow:auto; margin-left: auto; margin-right: auto; font-family: Nunito; height: 99vh; border-radius: 4px;`
const HeaderDiv = styled.div`overflow:auto; margin-bottom:3px; width: 99%;`
const TitleSpan = styled.span`color: white; font-size:18px; font-weight: bold; vertical-align: top; word-wrap: break-word; width: 45%; float:left;padding-left: 10px; padding-top: 5px;`

function Home(props) {	
	
	document.title = "VIDE PLAYLISTS"

	const pageBackgroundColor = '#a6c6ff'	
	return (
		<MainAppView>
			<HeaderDiv>
				<TitleSpan>VIDEO PLAYLISTS</TitleSpan>
			</HeaderDiv>
			<div style={{width: '100%', margin: 'auto'}}>
				{/* <div style={{width: '100%', margin: 'auto', textAlign: 'center'}}>
					<SubjectHeader>PLAYLISTS</SubjectHeader>
					<div><PlayListLink to="/nursery-ryhmes-1">Nursery Rhymes</PlayListLink></div>
					<div>&nbsp;</div>
				</div> */}
			</div>
		</MainAppView>
	)
}

export default Home
