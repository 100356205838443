import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
// import { LazyLoadImage } from 'react-lazy-load-image-component'

function PlaylistItem(props) {

	const mainItem = useRef(0);
	
	const video = props.video
	const slug = props.slug
	const itemNumber = props.itemNumber
	const activeVideoID = props.activeVideoID
	const textColor = props.textColor

	useEffect(() => {
		let element = document.getElementById("selectedVideoItem"); 
		element.scrollIntoView();
	}, [slug])

	
	const PlayListItemLink = styled(Link)`font-family: Nunito; text-align: left; padding-top: 10px; border-bottom: solid 1px gray; text-decoration: none;`
	const Item = styled.div`font-size: 16px; min-height:30px; margin-top:5px; margin-bottom:5px; padding-top:5px; padding-left: 5px; border: dotted 1px #a0a0a0; color: ${textColor}; text-decoration: none; border-radius: 6px; margin-right:5px;`
	const ItemSelected = styled.div`font-size: 18px; font-weight: bold; color: ${textColor}; padding-left: 10px; border: solid 3px yellow; border-radius: 6px; padding-top:5px; padding-bottom:5px; margin-top:5px; margin-bottom:5px; margin-right:5px;`
	const ImagePart = styled.div`width: 80px; float: left; `
	const ItemText = styled.div`margin-left: 10px; vertical-align: top; float: left; width: calc(100% - 100px)`
	const Clearfix = styled.div`clear: both;`

	return (
		<PlayListItemLink to={`/${slug}/${itemNumber}` }>
			{ 
				(itemNumber === parseInt(activeVideoID)) ?
					<ItemSelected ref={mainItem} id="selectedVideoItem" onMouseOver={() => { mainItem.current.style.webkitBoxShadow = 'inset 0 0 4px rgba(0,0,0,0.9)'; } } onMouseLeave={() => { mainItem.current.style.webkitBoxShadow = '' }}>
						{
							video.url.includes("youtube.com/watch") ?								
								<ImagePart>
									<img src={ video.url.replace('https://www.youtube.com/watch?v=', 'https://i.ytimg.com/vi/') + '/hqdefault.jpg' } style={{width: 80}} />								
								</ImagePart>
							: null
						}
						<ItemText>{`${itemNumber + 1}- ${video.name} ${video.artist ? '(' + video.artist + ')' : ''} `}</ItemText>
						<Clearfix />
					</ItemSelected> 
				:
					<Item ref={mainItem} onMouseOver={() => { mainItem.current.style.webkitBoxShadow = 'inset 0 0 4px rgba(0,0,0,0.9)'; } } onMouseLeave={() => { mainItem.current.style.webkitBoxShadow = '' }}>
						{
							video.url.includes("youtube.com/watch") ?
								<ImagePart>
									<img src={ video.url.replace('https://www.youtube.com/watch?v=', 'https://i.ytimg.com/vi/') + '/hqdefault.jpg' } style={{width: 80}} threshold="1000" />
								</ImagePart>
							: null
						}
						<ItemText>{`${itemNumber + 1}- ${video.name} ${video.artist ? '(' + video.artist + ')' : ''} `}</ItemText>
						<Clearfix />
					</Item> 
			}
		</PlayListItemLink>
	)
}

export {
	PlaylistItem
}