import React from 'react'
import styled from 'styled-components'
import { PlaylistItem } from './playlistSubitems'

function Playlist(props) {

	const activeVideoID = props.activeVideoID
	const slug = props.slug
	const videos = props.videos
	const textColor = props.textColor
	const settings = props.settings

	let items = []
	let itemNumber = 0
	for (let i = 0; i < videos.length; i++) {
		if (settings !== 'only') {
			items.push(
				<PlaylistItem video={videos[i]} slug={slug} key={i} itemNumber={itemNumber} activeVideoID={activeVideoID} textColor={textColor} />
			)
		}
		itemNumber++
	}

	if (props.reverseList) {
		items = items.reverse()
	}
	
	const PlaylistObj = styled.div``
	return (
		<PlaylistObj>
			{ 
				items
			}
		</PlaylistObj>
	)
}

export {
	Playlist
}