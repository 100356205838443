let vDuaEzber1 = [
	{ id: 0, name: 'SÜBHANEKE', url: 'https://drive.google.com/u/0/uc?id=12-spuvZ3QMV2OVRau8-GQuAevi8WX25n', artist: 'Mehmet Emin Ay' },
	{ id: 1, name: 'TAHİYYAT', url: 'https://drive.google.com/u/0/uc?id=1uqTymvLNfrilrFFItgosR7xQ1GQb4UTi', artist: 'Mehmet Emin Ay'},
	{ id: 2, name: 'ALLAHÜMME SALLİ', url: 'https://drive.google.com/u/0/uc?id=14UvVpeuO1e1eLxZ2o6ir8AsBxUsH5fKD', artist: 'Mehmet Emin Ay'},
	{ id: 3, name: 'ALLAHÜMME BARİK', url: 'https://drive.google.com/u/0/uc?id=1kosz2f3sk599OqoGj8qGJDNMTuxeXQec', artist: 'Mehmet Emin Ay'},
	{ id: 4, name: 'RABBENA', url: 'https://drive.google.com/u/0/uc?id=1JaVjgqzaBmRIiHZT1mv1rtsTEA75PtNh', artist: 'Mehmet Emin Ay'},
	{ id: 5, name: 'RABBENAĞFİRLİ', url: 'https://drive.google.com/u/0/uc?id=1_WnKVVIHezu_D5k16o0UfXZKl7vndliu', artist: ''},
	{ id: 6, name: 'KUNUT DUASI 1', url: 'https://drive.google.com/u/0/uc?id=1Ov20A6ZLs7w5mYNgh6AZG9GOo4DoniIe', artist: 'Fatih Collak'},
	{ id: 7, name: 'KUNUT DUASI 2', url: 'https://drive.google.com/u/0/uc?id=16GuSoiWKGOGkFVpQJx0YwJS5hhUcDKRa', artist: 'Fatih Collak'}
]
let vDuaEzber2 = [
	{ id: 0, name: 'SÜBHANEKE', url: 'https://drive.google.com/u/0/uc?id=1R-UA8giTNXemeNTP3eohPW-2dO_C_GnO', artist: 'Fatih Collak' },
	{ id: 1, name: 'TAHİYYAT', url: 'https://drive.google.com/u/0/uc?id=1TWXH4-aGBsemqVivEsXgWxI2Sjr3M1xe', artist: 'Fatih Collak'},
	{ id: 2, name: 'ALLAHÜMME SALLİ', url: 'https://drive.google.com/u/0/uc?id=1NUJ3vbe1sTbtBSyf31b-8Q7ju44rdXp9', artist: 'Fatih Collak'},
	{ id: 3, name: 'ALLAHÜMME BARİK', url: 'https://drive.google.com/u/0/uc?id=1PWUzoWBdjCTQzHHhwztUXmCpyoK59Pew', artist: 'Fatih Collak'},
	{ id: 4, name: 'RABBENA', url: 'https://drive.google.com/u/0/uc?id=1V6wbO13_wlx2IktoM2QxnwrOJnE37NE4', artist: 'Fatih Collak'},
	{ id: 5, name: 'RABBENAĞFİRLİ', url: 'https://drive.google.com/u/0/uc?id=1YCOOZfWDssfvk3JTqXF9ryw4iFjeSsDF', artist: 'Fatih Collak'},
	{ id: 6, name: 'KUNUT DUASI 1', url: 'https://drive.google.com/u/0/uc?id=1Ov20A6ZLs7w5mYNgh6AZG9GOo4DoniIe', artist: 'Fatih Collak'},
	{ id: 7, name: 'KUNUT DUASI 2', url: 'https://drive.google.com/u/0/uc?id=16GuSoiWKGOGkFVpQJx0YwJS5hhUcDKRa', artist: 'Fatih Collak'}
]
let vDuaEzber3 = [
	{ id: 0, name: 'SÜBHANEKE', url: 'https://drive.google.com/u/0/uc?id=1Qc5VNs_PwOmqdvujcflZtmYtK2efi_ya', artist: 'Davut Kaya' },
	{ id: 1, name: 'TAHİYYAT', url: 'https://drive.google.com/u/0/uc?id=15qkNPhpvddOvnQ0pXpsmoNSEDJvXDPSJ', artist: 'Davut Kaya'},
	{ id: 2, name: 'ALLAHÜMME SALLİ', url: 'https://drive.google.com/u/0/uc?id=16t2tzNhXopf610ELZqpn4xDHOb4DP1gR', artist: 'Davut Kaya'},
	{ id: 3, name: 'ALLAHÜMME BARİK', url: 'https://drive.google.com/u/0/uc?id=1I-7LMUiRjAGYwMOKJS_CM2N05GxY4Vai', artist: 'Davut Kaya'},
	{ id: 4, name: 'RABBENA', url: 'https://drive.google.com/u/0/uc?id=1guQv6Isq0ttYt-TcxpKQpd4P63CxyxUa', artist: 'Davut Kaya'},
	{ id: 5, name: 'RABBENAĞFİRLİ', url: 'https://drive.google.com/u/0/uc?id=12zkyWbGXVLHbQOiCOQ0FXkaLXNP3K4oH', artist: 'Davut Kaya'},
	{ id: 6, name: 'KUNUT DUASI 1', url: 'https://drive.google.com/u/0/uc?id=1G0k7RYCXqq8bRa108-z1NNW5erjcp_fs', artist: 'Davut Kaya'},
	{ id: 7, name: 'KUNUT DUASI 2', url: 'https://drive.google.com/u/0/uc?id=1am0JnfRkbpEFmshSPc_UbKab5Mxrs3vI', artist: 'Davut Kaya'}
]
let vDuaEzberTekrarli1 = [
	{ id: 0, name: 'SÜBHANEKE', url: 'https://drive.google.com/u/0/uc?id=1eYq46wXM9cM-j2WTiLB7NeC-zq4S3y9b', artist: 'Mustafa Yigit' },
	{ id: 1, name: 'TAHİYYAT', url: 'https://drive.google.com/u/0/uc?id=1GTKLhwH3Wo0zYqfwPm1npAfvdCH1vP94', artist: 'Mustafa Yigit'},
	{ id: 2, name: 'ALLAHÜMME SALLİ', url: 'https://drive.google.com/u/0/uc?id=1VqTcEYYaXqXTxqgwb71UwEmX8OytyDgW', artist: 'Mustafa Yigit'},
	{ id: 3, name: 'ALLAHÜMME BARİK', url: 'https://drive.google.com/u/0/uc?id=1ELSD7a_HGGlgSExXgZS-CSV2lMA7EOnJ', artist: 'Mustafa Yigit'},
	{ id: 4, name: 'RABBENA', url: 'https://drive.google.com/u/0/uc?id=1ohKM-chgJGvWaghvKn4UWfoPhZRgBw8W', artist: 'Mustafa Yigit'},
	{ id: 5, name: 'RABBENAĞFİRLİ', url: 'https://drive.google.com/u/0/uc?id=1dhfjr0o_qYIWEnCL33Ie16MI5AsC7-cW', artist: 'Mustafa Yigit'},
	{ id: 6, name: 'KUNUT DUALARI', url: 'https://drive.google.com/u/0/uc?id=1GexBVlowAEEwmyHB3B-0smC7UuWthvmN', artist: 'Mustafa Yigit'},
]
let vKisaSureler = [
	{ id: 0, name: 'FİL SURESİ', url: 'https://drive.google.com/u/0/uc?id=1gCwLjNnETEyfZRALG4wX7ZmqYYtVGPNP', artist: 'Davut Kaya' },
	{ id: 1, name: 'KUREYŞ SURESİ', url: 'https://drive.google.com/u/0/uc?id=1fiqnbOUmmVNpD3wmprFG6S8tOLfuze53', artist: 'Davut Kaya' },
	{ id: 2, name: 'MAUN SURESİ', url: 'https://drive.google.com/u/0/uc?id=1Awav2qW7KqmZ7_bX4NDm3AmgP38U3_n-', artist: 'Davut Kaya' },
	{ id: 3, name: 'KEVSER SURESİ', url: 'https://drive.google.com/u/0/uc?id=1ZSLXVKX96YVD1_s_YBn2K7h-43WkLjTs', artist: 'Davut Kaya' },
	{ id: 4, name: 'KAFIRUN SURESİ', url: 'https://drive.google.com/u/0/uc?id=1k_LYCxwZn09z9YHSmGhKon25ELrdEISD', artist: 'Davut Kaya' },
	{ id: 5, name: 'NASR SURESİ', url: 'https://drive.google.com/u/0/uc?id=1xarKSBjlEJCy5n0-dBAU-gMPELA8addr', artist: 'Davut Kaya' },
	{ id: 6, name: 'TEBBET SURESİ', url: 'https://drive.google.com/u/0/uc?id=1ymr1wUMWgkvfPS6vqI7ZsV8VpBEupyfV', artist: 'Davut Kaya' },
	{ id: 7, name: 'İHLAS SURESİ', url: 'https://drive.google.com/u/0/uc?id=1Mc86uIPbC1FLUNbg2PhSiynKQaBIA9Oj', artist: 'Davut Kaya' },
	{ id: 8, name: 'FELAK SURESİ', url: 'https://drive.google.com/u/0/uc?id=119aMJLFqDSm4gJZ1IMd-ue4RdMCX_eE8', artist: 'Davut Kaya' },
	{ id: 9, name: 'NAS SURESİ', url: 'https://drive.google.com/u/0/uc?id=1uqCq79a0pWyBoK3WZQKdgn8MEaTfuNWg', artist: 'Davut Kaya' },
	{ id: 10, name: 'ASR SURESİ', url: 'https://drive.google.com/u/0/uc?id=1XIkNN1QdhsWI6fkbXO-vEkAYuzC5N5JW', artist: 'Davut Kaya' },
	{ id: 11, name: 'AYETEL KURSI', url: 'https://drive.google.com/u/0/uc?id=18RDg4nFLHDTY6qTInn50PN6Qh2uYA9wJ', artist: 'Davut Kaya' },
	{ id: 12, name: 'BAKARA 285-286', url: 'https://drive.google.com/u/0/uc?id=11kYRM5CLSnDFXc2us7Yf9z2VABnVFFSv', artist: 'Davut Kaya' },
	{ id: 13, name: 'HASR 22-24', url: 'https://drive.google.com/u/0/uc?id=10Vk7JmPS4JiR04YaEbDumoA3nfPYdg2_', artist: 'Davut Kaya' },
]
let vKisaSurelerTekrarli1 = [
	{ id: 0, name: 'FATİHA SURESİ', url: 'https://drive.google.com/u/0/uc?id=1eEFhUaOHmdAXD4p3LLY9wzLeKqj5FOJZ', artist: 'Mustafa Yigit' },
	{ id: 1, name: 'FİL SURESİ', url: 'https://drive.google.com/u/0/uc?id=1aMgjLdzqqN26bkOFpZAVFDS-cie2CvuL', artist: 'Mustafa Yigit' },
	{ id: 2, name: 'KUREYŞ SURESİ', url: 'https://drive.google.com/u/0/uc?id=1Ldfz8S682Ct8uj2s_8TMi5K9goijtqvj', artist: 'Mustafa Yigit' },
	{ id: 3, name: 'MAUN SURESİ', url: 'https://drive.google.com/u/0/uc?id=1iCNlNjCJd212C6Y5aMtNgllluVan9R3y', artist: 'Mustafa Yigit' },
	{ id: 4, name: 'KEVSER SURESİ', url: 'https://drive.google.com/u/0/uc?id=1SyaKPUhfwrxmL6kg3JhQ4OHXrKapq9dM', artist: 'Mustafa Yigit' },
	{ id: 5, name: 'KAFIRUN SURESİ', url: 'https://drive.google.com/u/0/uc?id=1uudcq3mOomibNloDpgjjqUIWPi2E5ZAi', artist: 'Mustafa Yigit' },
	{ id: 6, name: 'NASR SURESİ', url: 'https://drive.google.com/u/0/uc?id=1NN3VfpAyT8SUIFpnmRoBm9jJGB56zFah', artist: 'Mustafa Yigit' },
	{ id: 7, name: 'TEBBET SURESİ', url: 'https://drive.google.com/u/0/uc?id=1cL8sa2d8DejnZoGavRl0kv_6MvXKVhjr', artist: 'Mustafa Yigit' },
	{ id: 8, name: 'İHLAS SURESİ', url: 'https://drive.google.com/u/0/uc?id=16eGHVf5lmOHCJqIgaTkm8RNc1M1h_Ne7', artist: 'Mustafa Yigit' },
	{ id: 9, name: 'FELAK SURESİ', url: 'https://drive.google.com/u/0/uc?id=1w492ia4uh8zlXTun8qzbLI9VwSUcPNjE', artist: 'Mustafa Yigit' },
	{ id: 10, name: 'NAS SURESİ', url: 'https://drive.google.com/u/0/uc?id=1cdYQaiyc5qZ0ZHq44Y-LYwfNuJ7O6AeW', artist: 'Mustafa Yigit' },
	{ id: 11, name: 'HASR 21-24 (LEV-ENZELNA)', url: 'https://drive.google.com/u/0/uc?id=10_pgqY62MkJFo4y9RvrwJcJt8i5uyVKz', artist: '' },
]

let vDuhaOncesiKisaSurelerTekrarli1 = [
	{ id: 0, name: 'ADİYAT SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1ZYoeQ1iomv-kIlJOsrWtkH0q17DjkH8s', artist: 'Davut Kaya' },
	{ id: 1, name: 'ALAK SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1gylzztvTdVXQOi1Lol78lqzVIvm3hJXS', artist: 'Davut Kaya' },
	{ id: 2, name: 'ASR SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1vJCDUm5160Cp3EiNr3dEszXTEgjJgO0V', artist: 'Davut Kaya' },
	{ id: 3, name: 'BEYYİNE SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1xVVq0hR3SJa5cip5tCnbgPIWgRBQHHFu', artist: 'Davut Kaya' },
	{ id: 4, name: 'DUHA SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1P1LtxuB5nFV9gMc7YSs8oOzboO2sD1Gv', artist: 'Davut Kaya' },
	{ id: 5, name: 'HÜMEZE SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1uf_24ONPi1IbS3c3WcQt13Pt0pQOjM9z', artist: 'Davut Kaya' },
	{ id: 6, name: 'İNŞİRAH SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=14NSetuaIz23nWbPeQ6titK4hyqAgd4Aa', artist: 'Davut Kaya' },
	{ id: 7, name: 'KADİR SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1ednRe8MH9KBa8zQCyGPRVPrLgUBhvYsh', artist: 'Davut Kaya' },
	{ id: 8, name: 'KARİA SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1KDBndq1Bkf4oMOG9slnZoJ4D1_kKIj0M', artist: 'Davut Kaya' },
	{ id: 9, name: 'TEKASÜR SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1GQKIbjvmMXKPou06-atKDMmYloLmCnBr', artist: 'Davut Kaya' },
	{ id: 10, name: 'TİN SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=1mFc5HSfLYzDX89t3S6PVyzZcQ5yq1GkR', artist: 'Davut Kaya' },
	{ id: 11, name: 'ZİLZAL SURESİ - TEKRARLI 1', url: 'https://drive.google.com/u/0/uc?id=17KnVn4L2_0VcNoWtWjS08XcdtS0kcf0f', artist: 'Davut Kaya' },	
]
let vDuhaOncesiKisaSurelerTekrarli2 = [
	{ id: 0, name: 'ADİYAT SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=1V6j8MLQVAWlMkqbPrL2mTRgDZn4ZqD9l', artist: 'Fatih Çollak' },
	{ id: 1, name: 'ALAK SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=1jw9Pqvg0j35z7t_QfmfI9hTjFssllEek', artist: 'Fatih Çollak' },
	{ id: 2, name: 'ASR SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=1NlohKUFV1ikYsoJ1-dOQu6JkVgn1O3FO', artist: 'Fatih Çollak' },
	{ id: 3, name: 'BEYYİNE SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=1qj_1SWnQJ0n0NGTv2IIi7PlfakMdHSmI', artist: 'Fatih Çollak' },
	{ id: 4, name: 'DUHA SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=1OZLz7J6ttjZquixagHsD0qcpg3hR0EBy', artist: 'Fatih Çollak' },
	{ id: 5, name: 'HÜMEZE SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=1tLG83QEQURSfFIQt14Ihp_z0GFt92j7A', artist: 'Fatih Çollak' },
	{ id: 6, name: 'İNŞİRAH SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=13k2XNlHGFFFcpBSX6VHaaWnDs_idEePf', artist: 'Fatih Çollak' },
	{ id: 7, name: 'KADİR SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=1j-HpjOJapsTkDDUghBsViRCo88a8Je2P', artist: 'Fatih Çollak' },
	{ id: 8, name: 'KARİA SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=19xGLMBo9Homb2GzTN_jPaKbrDCdg968j', artist: 'Fatih Çollak' },
	{ id: 9, name: 'TEKASÜR SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=1bcU5u5rDntU2qHR36AWYZ54n6ZEylFg6', artist: 'Fatih Çollak' },
	{ id: 10, name: 'TİN SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=184f0W5nuvZLvDaq4FmWI4QsI05LFrJpj', artist: 'Fatih Çollak' },
	{ id: 11, name: 'ZİLZAL SURESİ - TEKRARLI 2', url: 'https://drive.google.com/u/0/uc?id=1yt6fPpL3VhgonS8T5MG9EsnK6vnh6rct', artist: 'Fatih Çollak' },	
]
let vDuhaOncesiKisaSurelerTekrarli3 = [
	{ id: 0, name: 'ADİYAT SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1oTw19tdGsJlwf1oZJWqQYem0pnxstvs8', artist: 'Mishary Rashid al Afasy' },
	{ id: 1, name: 'ALAK SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1XPmRd4zO7G384fXQm3Uj_Cmicka98iCg', artist: 'Mishary Rashid al Afasy' },
	{ id: 2, name: 'ASR SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1T3OdIeJzPios6I-iSd19L-sDEBVUttD5', artist: 'Mishary Rashid al Afasy' },
	{ id: 3, name: 'BEYYİNE SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1R0Dz0932sRWilUVb56S5MFpx9846Xnjh', artist: 'Mishary Rashid al Afasy' },
	{ id: 4, name: 'DUHA SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1weRksm2OAggswBEwbvhcJiBTnYNf5uw7', artist: 'Mishary Rashid al Afasy' },
	{ id: 5, name: 'HÜMEZE SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1nRS6dh-BB5pNIrH8Qgw5QSYsVcqwmzco', artist: 'Mishary Rashid al Afasy' },
	{ id: 6, name: 'İNŞİRAH SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=12alUF0W7wTDSl5yvPjKHdhYwqRSoz4-k', artist: 'Mishary Rashid al Afasy' },
	{ id: 7, name: 'KADİR SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1TAC7qLiqDUJk9PERVeGGWQ47escETxZd', artist: 'Mishary Rashid al Afasy' },
	{ id: 8, name: 'KARİA SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1M-hgnmvnnrnMh0VpGivWzExQ8FSCmszs', artist: 'Mishary Rashid al Afasy' },
	{ id: 9, name: 'TEKASÜR SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1AA8JW1bw4-c9UFtGG2m2vaOFQcar6ROa', artist: 'Mishary Rashid al Afasy' },
	{ id: 10, name: 'TİN SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=18Bdecbc64s6ApEqky9T_6PDEUnO34gpc', artist: 'Mishary Rashid al Afasy' },
	{ id: 11, name: 'ZİLZAL SURESİ - TEKRARLI 3', url: 'https://drive.google.com/u/0/uc?id=1256fyZ8oV5O4G5j9IJ24WZOGwduaaUSP', artist: 'Mishary Rashid al Afasy' },	
]


let vDuhaOncesiKisaSurelerTekOkuma1 = [
	{ id: 0, name: 'ADİYAT SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1CdGiqMCT5H-kyhDBU5fSRQ9bkw6Zm4Bj', artist: 'Abdulaziz az Zahrani' },
	{ id: 1, name: 'ALAK SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=15P9lOJ8CCgoLCrTMtE3nhJyHanaLTskO', artist: 'Abdulaziz az Zahrani' },
	{ id: 2, name: 'ASR SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=15eXTmFrW0_FuhOtmzOhPSwf2hBqPnWGy', artist: 'Abdulaziz az Zahrani' },
	{ id: 3, name: 'BEYYİNE SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1MIli9ulYn1nKzBfsaujazk7jC0gBfw3S', artist: 'Abdulaziz az Zahrani' },
	{ id: 4, name: 'DUHA SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1BxDmjvuHFNxN4LzSSJHeKoESkGY7wRxW', artist: 'Abdulaziz az Zahrani' },
	{ id: 5, name: 'HÜMEZE SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1W-7t6EPecQxchyIYQAkvuvPSKxr2QEH4', artist: 'Abdulaziz az Zahrani' },
	{ id: 6, name: 'İNŞİRAH SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1n7_v7O341PN788eCSia2Coc5xo0zImVe', artist: 'Abdulaziz az Zahrani' },
	{ id: 7, name: 'KADİR SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1wQwRWMrZ9FUX0uI2EtMMaz--38m3pnMn', artist: 'Abdulaziz az Zahrani' },
	{ id: 8, name: 'KARİA SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1Z9PuIopUwQuLWBuN2nRRQfkmCQ2IvCF_', artist: 'Abdulaziz az Zahrani' },
	{ id: 9, name: 'TEKASÜR SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1l-ZqOSeZwExuqh3WDVv68iLpM0geouqW', artist: 'Abdulaziz az Zahrani' },
	{ id: 10, name: 'TİN SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1CzQ6n3c49bw69kU1dOaLh3y57OujTx_S', artist: 'Abdulaziz az Zahrani' },
	{ id: 11, name: 'ZİLZAL SURESİ 1', url: 'https://drive.google.com/u/0/uc?id=1T9PrV2p4tyajShx1LGXNixg8Vj7A4Gik', artist: 'Abdulaziz az Zahrani' },	
]
let vDuhaOncesiKisaSurelerTekOkuma2 = [
	{ id: 0, name: 'ADİYAT SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=1qN9MoTC5fjrnbVy7Hvy-5caNmvkpE_qm', artist: 'Abdurrahman El Ussi' },
	{ id: 1, name: 'ALAK SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=1cS-KJZEkHHZUKiClrSgzzcFsnVprQU6Z', artist: '' },
	{ id: 2, name: 'ASR SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=11rzltulBov48q14Biw7lgXXK3c3QFnT3', artist: '' },
	{ id: 3, name: 'BEYYİNE SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=1I5jGuRaXwYqocbPgheQrzM06nvNexauL', artist: '' },
	{ id: 4, name: 'DUHA SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=1GzgZ6AVXBy0GRe0EJknZBcl74wnk0fln', artist: '' },
	{ id: 5, name: 'HÜMEZE SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=1dtI6J0TXaEqtQeNyzY0dWB5nXPfF8tUT', artist: '' },
	{ id: 6, name: 'İNŞİRAH SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=1027DdrvK6KcS4xxzgmgnjrlI1U7ioVnS', artist: '' },
	{ id: 7, name: 'KADİR SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=1xnOaOL8zmJwc8mk2waqq5BQ53FU7D8r2', artist: '' },
	{ id: 8, name: 'KARİA SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=1FEaCumyZ5h6ao9qsE9ysPEK_8kNY7sq3', artist: '' },
	{ id: 9, name: 'TEKASÜR SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=13tpm6PWXcbPx7kIi2FHu265qFwLT1Xjk', artist: '' },
	{ id: 10, name: 'TİN SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=1qgV5tO3c0N__MGJKFpK0zh246XvdSWY2', artist: '' },
	{ id: 11, name: 'ZİLZAL SURESİ 2', url: 'https://drive.google.com/u/0/uc?id=19bVNwlZsFo3I0NPlpYmvVap_AmWTa6Xa', artist: '' },	
]
let vDuhaOncesiKisaSurelerTekOkuma3 = [
	{ id: 0, name: 'ADİYAT SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=12dBFZQLelc-5i9JfPqfVPJxl_VBhpMs7', artist: 'Nasser al Qatami' },
	{ id: 1, name: 'ALAK SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=19l3-eVoyApG5xMEKwmeQ_uhwnIEi6apr', artist: 'Nasser al Qatami' },
	{ id: 2, name: 'ASR SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=1CdCRDwl0s2KT9Zdl4wnHeUFyT_cBhoFd', artist: 'Nasser al Qatami' },
	{ id: 3, name: 'BEYYİNE SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=1gqFBb-gR7BvkY_ezLGCwZT2nvE4_wVt4', artist: 'Nasser al Qatami' },
	{ id: 4, name: 'DUHA SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=1g3P46lvB2oosiktRx_dFPyZPGDTbpdtl', artist: 'Nasser al Qatami' },
	{ id: 5, name: 'HÜMEZE SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=1mkbAUd9OQtkrw0QSY6vDwlUgWRGxBbTT', artist: 'Nasser al Qatami' },
	{ id: 6, name: 'İNŞİRAH SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=10R9GRcqCve9fDgSZjy7HH6JBN7uHm7WO', artist: 'Nasser al Qatami' },
	{ id: 7, name: 'KADİR SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=1s6yKG4Fsbs2Rh49hwt4NxuPSLx6B6L-c', artist: 'Nasser al Qatami' },
	{ id: 8, name: 'KARİA SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=1d0q0sa2feyyHJEW6Tv_cLqoB7819gA85', artist: 'Nasser al Qatami' },
	{ id: 9, name: 'TEKASÜR SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=1QHG2XxYBUxfE0Zol0BhB7T_D6on4rRnM', artist: 'Nasser al Qatami' },
	{ id: 10, name: 'TİN SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=1TQK8g5i2Djl7ZhNjBE5vdc-H2DpzTcoP', artist: 'Nasser al Qatami' },
	{ id: 11, name: 'ZİLZAL SURESİ 3', url: 'https://drive.google.com/u/0/uc?id=1RTPuNgStCx1G0ezbXvH5sCfLNzy_oZnT', artist: 'Nasser al Qatami' },	
]
let vDuhaOncesiKisaSurelerTekOkuma4 = [
	{ id: 0, name: 'ADİYAT SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=12ipU8AdpdXGP7xOLWbg1uZPdxdi9cP70', artist: 'Muflih Safitra' },
	{ id: 1, name: 'ALAK SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=1teLYjJgAyn0aOz2-9R8TDP6NLE4AkABS', artist: 'Muflih Safitra' },
	{ id: 2, name: 'ASR SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=1L3GveevSp-HronLiB_zRZ4k3BmcqMUhr', artist: 'Muflih Safitra' },
	{ id: 3, name: 'BEYYİNE SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=16ryS10YVabiMUjjPju3oVVsQgwIamYV5', artist: 'Muflih Safitra' },
	{ id: 4, name: 'DUHA SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=1vvB2R8V6iI0Sz6w5yXLNSwmDitqn6NU4', artist: 'Muflih Safitra' },
	{ id: 5, name: 'HÜMEZE SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=12-6s1x_lrrKpbVMKQRZ9Gv4BhJeapx2U', artist: 'Muflih Safitra' },
	{ id: 6, name: 'İNŞİRAH SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=1GyHYE2V5HQnR5ZkQzDzX4eBmS1F7cjEz', artist: 'Muflih Safitra' },
	{ id: 7, name: 'KADİR SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=1QhyoPg5hVmWyte4NfyYk_l7pRccpX1aM', artist: 'Muflih Safitra' },
	{ id: 8, name: 'KARİA SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=1ZQMZ4UNZUIhhFgx79_mSsqq57LUgXbwH', artist: 'Muflih Safitra' },
	{ id: 9, name: 'TEKASÜR SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=1I2yVIF_NyzHXnBXKmOvgJ2EVIOl8r4WW', artist: 'Muflih Safitra' },
	{ id: 10, name: 'TİN SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=1Pcup-YfATnQYlXQbvYhofVLzAwBywByZ', artist: 'Muflih Safitra' },
	{ id: 11, name: 'ZİLZAL SURESİ 4', url: 'https://drive.google.com/u/0/uc?id=12OIMKmJz7fWQ17rJN_yFXWHl3xur86bt', artist: 'Muflih Safitra' },	
]
let vDuhaOncesiKisaSurelerTekOkuma5 = [
	{ id: 0, name: 'ADİYAT SURESİ 5', url: 'https://drive.google.com/u/0/uc?id=18309IN-Tdn4SWpU1DEfzGA33Y_YC7rDU', artist: 'Mansur al Salimi' },
	{ id: 1, name: 'BEYYİNE SURESİ 5', url: 'https://drive.google.com/u/0/uc?id=1_5aL7vGmOKwZNjgtoYPJ-85GU23LcW9c', artist: 'Mansur al Salimi' },
	{ id: 2, name: 'DUHA SURESİ 5', url: 'https://drive.google.com/u/0/uc?id=1qQpI58VuZg8lsoakgdydYYOurm0ZzHhP', artist: 'Wadi al Yamani' },
	{ id: 3, name: 'HÜMEZE SURESİ 5', url: 'https://drive.google.com/u/0/uc?id=1HFUBb2W10jnIZEJQ2c5NdAyH2ITjPNt0', artist: 'Muhammed al Muqit' },
	{ id: 4, name: 'ZİLZAL SURESİ 5', url: 'https://drive.google.com/u/0/uc?id=1RnnZJ09s0Lvz8yntPhVrez2h09pdhdFG', artist: 'Mansur al Salimi' },	
]

let vCevsen1 = [
	{ id: 0, name: '1-10 BAB', url: 'https://drive.google.com/u/0/uc?id=1HKAW_tPe_VS1sI6SPh24LRvgcigD5-Li', artist: '' },
	{ id: 1, name: '11-20 BAB', url: 'https://drive.google.com/u/0/uc?id=1zwnNfwSCJOOrSYFeYZFF0mgQIoK1h8aD', artist: '' },
	{ id: 2, name: '21-30 BAB', url: 'https://drive.google.com/u/0/uc?id=1hGvr2ZJ0TGfZxW7ExkwoH3hw6XUrX2Gi', artist: '' },
	{ id: 3, name: '31-40 BAB', url: 'https://drive.google.com/u/0/uc?id=1I1KwuBXG3VZQXj1oOAndr1oWnLhYmJ01', artist: '' },
	{ id: 4, name: '41-50 BAB', url: 'https://drive.google.com/u/0/uc?id=1Epe_eR83UYki0K3-krKnEjvb0cOJY71d', artist: '' },
	{ id: 5, name: '51-60 BAB', url: 'https://drive.google.com/u/0/uc?id=12X4eCWDDwpXY2IbKJDJ0ORHLUao5BdHk', artist: '' },
	{ id: 6, name: '61-70 BAB', url: 'https://drive.google.com/u/0/uc?id=1Tts1SYllzYCb86Gp6CiDCAv7CxWJnm5F', artist: '' },
	{ id: 7, name: '71-80 BAB', url: 'https://drive.google.com/u/0/uc?id=1_zTJwUwGfzpmc8sMDX7hxVbAwPpIk1_S', artist: '' },
	{ id: 8, name: '81-90 BAB', url: 'https://drive.google.com/u/0/uc?id=1WxOUKEaM0C06_yR9I5ItriBMiTgy8Nqp', artist: '' },
	{ id: 9, name: '91-100 BAB', url: 'https://drive.google.com/u/0/uc?id=19mEak8YdohFtQEiu9gMWqoO-XjyqgGmO', artist: '' },
]
let vErtugrulErkisi1 = [
	{ id: 0, name: 'Affeyle', url: 'https://drive.google.com/u/0/uc?id=1dAHVMiy-Rm3eGrRjVwx0x_DdM7F1p3sn', artist: 'Ertuğrul Erkişi' },
	{ id: 1, name: 'Dün Gece', url: 'https://drive.google.com/u/0/uc?id=1w11bmPP12jwY_nW5cYx15j2DLji0wgrd', artist: 'Ertuğrul Erkişi' },
	{ id: 2, name: 'Gönül Sızım', url: 'https://drive.google.com/u/0/uc?id=1eDgRIQ2XMCDC2wjPfnqQSkOjqNTLa2C-', artist: 'Ertuğrul Erkişi' },
	{ id: 3, name: 'Her Aşığın Dilinde', url: 'https://drive.google.com/u/0/uc?id=1o4w-Fkhob-CUxRkDBAksn1C7HIyPQw2P', artist: 'Ertuğrul Erkişi' },
	{ id: 4, name: 'İnleyen Bir Nayım', url: 'https://drive.google.com/u/0/uc?id=1ByMmFypvptJN6REcM5qWkzsocGhiWOdx', artist: 'Ertuğrul Erkişi' },
	{ id: 5, name: 'Güllerin Efendisi', url: 'https://drive.google.com/u/0/uc?id=1I9mglJmTKp2MHDpoJ5KE1gsn2SxwlSZG', artist: 'Ertuğrul Erkişi' },
]
let vEsmaiHusna1 = [
	{ id: 0, name: 'Esmaül Hüsna 1', url: 'https://drive.google.com/u/0/uc?id=10zatWXudVert7fuw-k3pNW962pSD9iWS', artist: 'Dünya Çocukları' },
	{ id: 1, name: 'Esmaül Hüsna 2', url: 'https://drive.google.com/u/0/uc?id=1x6zljOJwKwOKXFEzlbBYWVxViPF4LTby', artist: 'Dünya Çocukları' },
]
let vMinikDualarGrubu1 = [
	{ id: 0, name: 'Anne Baba Duası', url: 'https://drive.google.com/u/0/uc?id=1UMFesIgjZv35OR9bPyGOoVbAqaWFjsXI', artist: 'Minik Dualar Grubu' },
	{ id: 1, name: 'Tesbihat', url: 'https://drive.google.com/u/0/uc?id=1ymiOP4ZPfWKOSSSa7HxlTygp4VnGE2_U', artist: 'Minik Dualar Grubu' },
	{ id: 2, name: 'Teşekkür Ederim Allahım', url: 'https://drive.google.com/u/0/uc?id=1BO-56uqUdeJCPNDv0ZhhSAGh-sCiXdxT', artist: 'Minik Dualar Grubu' },
]
let vYamanlarCocukKorosu = [
	{ id: 0, name: 'Aktaş Diye', url: 'https://drive.google.com/u/0/uc?id=1ZPsF4PN6-hVm9V6Wight-ZqND0cTFRkh', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 1, name: 'Altın Beşik', url: 'https://drive.google.com/u/0/uc?id=18HOzBhlVfwSmKw6t7RDry-5TzZRPl0Az', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 2, name: 'Anam Gidince', url: 'https://drive.google.com/u/0/uc?id=1fF1xo-HQ8qq9GodhL_YyyIDnVjMn7KXn', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 3, name: 'Anneme Türkü', url: 'https://drive.google.com/u/0/uc?id=1sUsOgiILXQa29Xcq28K0NIQ6PcYGdDKA', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 4, name: 'Benim Rabbim', url: 'https://drive.google.com/u/0/uc?id=1wHRoRvWgnBsSUbcqS2J9CWPXh87ox6-L', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 5, name: 'Bir Allah', url: 'https://drive.google.com/u/0/uc?id=1_GFir8VfHIr5O5Bs7JpRhH9Li8zCTUnT', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 6, name: 'Bilmece', url: 'https://drive.google.com/u/0/uc?id=18-ycWyEiB_3Gh5gXASI8ylT5fZfVKM41', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 7, name: 'Çay', url: 'https://drive.google.com/u/0/uc?id=1onvtnPdbw_u7_9NgIm-X2HrlAAh2DmQ9', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 8, name: 'Bir Büyüsem', url: 'https://drive.google.com/u/0/uc?id=1yF472xQdrkGNaM6Og3abFa_vBKLA3FkU', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 9, name: 'Çippiğim', url: 'https://drive.google.com/u/0/uc?id=1c_MoXakbp0ni-5aOlOOP-z483EKmuKr5', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 10, name: 'Dahası Var', url: 'https://drive.google.com/u/0/uc?id=1JZxGK9vvH26diD7DaAQ4DaiKPkAE4XIG', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 11, name: 'Define Bulalım', url: 'https://drive.google.com/u/0/uc?id=1vu_JG0wt0Gl3YyDcqnBC8LzAV2RDyMOV', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 12, name: 'Dört İstek', url: 'https://drive.google.com/u/0/uc?id=15z1Bf4b9uZgMRyBbT1QHSn8RtDEO9QhZ', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 13, name: 'Horozum', url: 'https://drive.google.com/u/0/uc?id=1v_fLlG9DTWT2ZKbyrZ1edr8JeGPeWZxn', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 14, name: 'Kedi', url: 'https://drive.google.com/u/0/uc?id=1OREFJE6vzbHiZyfvoeimTFAzOfXBJm8D', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 15, name: 'Ninni', url: 'https://drive.google.com/u/0/uc?id=1AkkaGsra3-4sOZJwa-2RPu-c-o378100', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 16, name: 'Sana Gül Desem', url: 'https://drive.google.com/u/0/uc?id=1zmiPXURlXZH6rshZZWE7h2nuEpzOKi_i', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 17, name: 'Tini Mini Hanım', url: 'https://drive.google.com/u/0/uc?id=1lNdCoBzVCDknx1WHscns4qeACOqUIZ3_', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 18, name: 'Tiridine Bandım', url: 'https://drive.google.com/u/0/uc?id=1AltCfOobrTeqKpuv_mkmJED0FNYvOKpw', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 19, name: 'Yağmur Hanım', url: 'https://drive.google.com/u/0/uc?id=199TnF9RuJT1djDMocODzyv2soplowVJx', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 20, name: 'Dua', url: 'https://drive.google.com/u/0/uc?id=1rKg9HH2ni6A6YfuRn9XeGg_9u_dBIXJJ', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 21, name: 'Dünya', url: 'https://drive.google.com/u/0/uc?id=1NBUYGGhrAnSrIjHgZ76WzCMVaxJKCokl', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 22, name: 'Eyvallah Şahım', url: 'https://drive.google.com/u/0/uc?id=1nlJrudtQJDgXjujfbe4nCCAO0Op7_6Ni', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 23, name: 'Gül Sultan', url: 'https://drive.google.com/u/0/uc?id=1ZpxtiZJkgNDkMCabTrCkE8Th2G4n5DNw', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 24, name: 'Güzelliğin On Para Etmez', url: 'https://drive.google.com/u/0/uc?id=1UqKfpsdY6Mm1UXamYxBnwkYifUw8mH-6', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 25, name: 'Horozum 2', url: 'https://drive.google.com/u/0/uc?id=1GFQ94QR2WCp0k9cUmFAlvWfpzC2LNuYY', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 26, name: 'Kavak', url: 'https://drive.google.com/u/0/uc?id=1xsZ-2cNrLU_DmcJQ5Rxtipyw4WeQ1DSw', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 27, name: 'Kim Yapıyor Bunları', url: 'https://drive.google.com/u/0/uc?id=1jErX_tGEGRQphbgMPcl0Rc7w_IC38OGN', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 28, name: 'Sevgili Anadolum', url: 'https://drive.google.com/u/0/uc?id=17nWBuqDNyDHvi2piW4_7bCndGsA8Ny8V', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 29, name: 'Var Elbet', url: 'https://drive.google.com/u/0/uc?id=1-hYfXrSMk4h-Qa_hEKdh-zlZsvnHVo1D', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 30, name: 'Vatan Çiçeği', url: 'https://drive.google.com/u/0/uc?id=1-XE_a-qN_eIfpQsfscWPFqtdiy6JNTXg', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 31, name: 'Yananlar', url: 'https://drive.google.com/u/0/uc?id=1sNiHfuf2hLkaeS-JbHXvMjoXWLZ44Bfp', artist: 'Yamanlar Cocuk Korosu' },
	{ id: 32, name: 'Yüksek Yüksek Tepelere', url: 'https://drive.google.com/u/0/uc?id=1wyIMfswlMC7Q9wPMlvLmDCIaimMAyvjt', artist: 'Yamanlar Cocuk Korosu' },
]
let vBarisManco1 = [
	{ id: 0, name: 'Ahmet Beyin Ceketi', url: 'https://drive.google.com/u/0/uc?id=1ZDaEPN2wu-7MgzO33d6pwj5lel_4ivyp', artist: 'Barış Manço' },
	{ id: 1, name: 'Ali Yazar Veli Bozar', url: 'https://drive.google.com/u/0/uc?id=18unqbSjXqHOnlUPnc4FkTooZCBXGjauS', artist: 'Barış Manço' },
	{ id: 2, name: 'Halil İbrahim Sofrası', url: 'https://drive.google.com/u/0/uc?id=1zLHdcgNKAagpy_xweqB-29jeLjpka0fL', artist: 'Barış Manço' },
	{ id: 3, name: 'Olmaya Devlet Cihanda', url: 'https://drive.google.com/u/0/uc?id=1r4emB2XvmQ9yoHF72CLBHxc7WHdwde69', artist: 'Barış Manço' },
	{ id: 4, name: 'Bugün Bayram', url: 'https://drive.google.com/u/0/uc?id=1ROtebw_tonZKD7thxbAOjk3_soHPRC8I', artist: 'Barış Manço' },
	{ id: 5, name: 'Hemşerim Memleket Nire', url: 'https://drive.google.com/u/0/uc?id=1gux75_7XB5yo7gGQQ4cvBLoAjn0USQnN', artist: 'Barış Manço' },
	{ id: 6, name: 'Nane Limon Kabuğu', url: 'https://drive.google.com/u/0/uc?id=123wFkL6ymPm8HeaP-dSYlBfqWnP9WBT0', artist: 'Barış Manço' },
	{ id: 7, name: 'Sarı Çizmeli Mehmet Ağa', url: 'https://drive.google.com/u/0/uc?id=1tBINq1LJxd9zHkal2gERmyVEl4MuYxum', artist: 'Barış Manço' },
	{ id: 8, name: 'Süleyman', url: 'https://drive.google.com/u/0/uc?id=1rHvSeXuCOk2SCdWPO_WRLrFpT14yEY9C', artist: 'Barış Manço' },

	{ id: 9, name: '40. Yıl', url: 'https://drive.google.com/u/0/uc?id=1ADqQtRGzfjlyg8Dy3ar35c9rK-5ns4Ap', artist: 'Barış Manço' },
	{ id: 10, name: 'Arkadaşım Eşşek', url: 'https://drive.google.com/u/0/uc?id=1681mEhxZ35PGxTVm7CiYugAIX9LRGA7-', artist: 'Barış Manço' },
	{ id: 11, name: 'Ayı', url: 'https://drive.google.com/u/0/uc?id=1sTmYH_WNud9GdBb1wvV6l9xDn5GauRSM', artist: 'Barış Manço' },
	{ id: 12, name: 'Benden Öte Benden Ziyade', url: 'https://drive.google.com/u/0/uc?id=1xvgHo_P8LGlKIs_NOzoxjn8pM8aQs0Br', artist: 'Barış Manço' },
	{ id: 13, name: 'Gidiyorum Gündüz Gece', url: 'https://drive.google.com/u/0/uc?id=1DAmz9ZP1p_i6FOqkNZEdtE150zoZG-tK', artist: 'Barış Manço - Cem Karaca' },
	{ id: 14, name: 'Dört Kapı', url: 'https://drive.google.com/u/0/uc?id=1JJGrpE_WQWSzyCn9k-JvqEBOHd9FTjBY', artist: 'Barış Manço' },
	{ id: 15, name: 'Geçti Dost Kervanı', url: 'https://drive.google.com/u/0/uc?id=18jZqUq8nJHe6KvUwnEf-ROtZDkgOaFs_', artist: 'Barış Manço' },
	{ id: 16, name: 'Ölüm Allahın Emri', url: 'https://drive.google.com/u/0/uc?id=1NDwg-rIp1VKdRbgqV2P_3rb_HZpf4unT', artist: 'Barış Manço' },
	{ id: 17, name: 'Ömrümün Sonbaharında', url: 'https://drive.google.com/u/0/uc?id=1r4xNHph_F9fxnfW4tS5CjRa0w50ifMBY', artist: 'Barış Manço' },
	{ id: 18, name: 'Sarı Çizmeli Mehmet Ağa', url: 'https://drive.google.com/u/0/uc?id=1OhfQNicShjbi1oEsxd_7JgQEbpPy5BD6', artist: 'Barış Manço' },
	{ id: 19, name: 'Yine Yol Göründü Gurbete', url: 'https://drive.google.com/u/0/uc?id=1O0OQHuhn-sT4i3ri2IKYt5b5ejRGVrQA', artist: 'Barış Manço' },
	{ id: 20, name: 'Dağlar Dağlar', url: 'https://drive.google.com/u/0/uc?id=1lB_PvVsbZaGmNuJZfZwZyVPJGQUcL58M', artist: 'Barış Manço' },
]

let vNamazTesbihatlari1 = [
	{ id: 0, name: 'Öğle Namazı Tesbihatı', url: 'https://drive.google.com/u/0/uc?id=1rhocDazX9JlqBJbKIUuXQtQQ9M-7k3lu', artist: '' },
]
let vYamanlarMuzikGrubu1 = [
	{ id: 0, name: 'İşte Öyle Birşey', url: 'https://drive.google.com/u/0/uc?id=1Ds-flMzs6CzcQhVhFw4CFXLD1jB4TWKo', artist: 'Yamanlar Müzik Grubu' },
	{ id: 1, name: 'Kara Sevda', url: 'https://drive.google.com/u/0/uc?id=1yuN5ItqFEHK1PO4twPWtVV2veAIXuM3P', artist: 'Yamanlar Müzik Grubu' },
	{ id: 2, name: 'Gülpembe', url: 'https://drive.google.com/u/0/uc?id=1ZLtulVQK9aDZzteG0D7RGCXzfCNyl8cC', artist: 'Yamanlar Müzik Grubu' },
]
let vTurkceOlimpiyatNaatGeceleri1 = [
	{ id: 0, name: 'Efendim', url: 'https://drive.google.com/u/0/uc?id=1FpM4JR9jpLCj3U646nNy45BqC2xWMIUS', artist: 'Selbi İlyasova' },	
	{ id: 1, name: 'Faniyim, Fani Olanı İstemem', url: 'https://drive.google.com/u/0/uc?id=1ZT5FPog-xDyUxDM7aogyEzle8vJsixqo', artist: '' },
	{ id: 2, name: 'Gel Vur Mızrabını', url: 'https://drive.google.com/u/0/uc?id=1mzlKmdnGa4eOIcl_AfPslvzbHViBgIX5', artist: '' },
	{ id: 3, name: 'Gönlümün Sultanı', url: 'https://drive.google.com/u/0/uc?id=1KrDyEIoehiObs-s0ZZtA9Wtbe2kqS04d', artist: 'Ruslan Annamammedov' },
	{ id: 4, name: 'Hicran ve Ümit', url: 'https://drive.google.com/u/0/uc?id=1XUq0SNGA0oXP2Zj4TAcKyt0J_BHUaUSD', artist: '' },
	{ id: 5, name: 'Nola Tacım Gibi', url: 'https://drive.google.com/u/0/uc?id=1Hew5gaNLlcuTYjONbDHYu4BC8TzLZOrC', artist: 'Selbi Ilyasova' },
	{ id: 6, name: 'Uyan Ey Gözlerim', url: 'https://drive.google.com/u/0/uc?id=1xZFjVJ5lZaL1tIccV0PRtGuZgUGriz_8', artist: '' },
	{ id: 7, name: 'Sayesi Düşmez Yere', url: 'https://drive.google.com/u/0/uc?id=10baOUlQUZOsuz4BelC-_N96f8WlifAPt', artist: 'Sohruh Yunusov' },
	{ id: 8, name: 'Sefinem Gark Oldu', url: 'https://drive.google.com/u/0/uc?id=11_eLY2DJCXrB_u1iuhdp7FRTFrzOAnQz', artist: '' },
	{ id: 9, name: 'Sen Mevlayı Sevende', url: 'https://drive.google.com/u/0/uc?id=1IKSZ4bj2vOdNwDJaGvGvkxol6MJfGxwh', artist: '' },
	{ id: 10, name: 'Tecellayı Cemalinden', url: 'https://drive.google.com/u/0/uc?id=1igtl8NlIZdiIPd2QZAWlHbQ5JDmwjRA2', artist: '' },
	{ id: 11, name: 'Ummanlar Gibi', url: 'https://drive.google.com/u/0/uc?id=1C6TKqd1iVLSTChVTSgF6gkAA0iHFTYC2', artist: 'A Ismail' },
	{ id: 12, name: 'Ya Rasulallah', url: 'https://drive.google.com/u/0/uc?id=1dSA-ZEEcevi5TR845Gg6XDDnzCnicXoI', artist: 'Otlivio Latuperissia' },
]

let vMaherZain = [
	{ id: 0, name: 'Mawlaya', url: 'https://drive.google.com/u/0/uc?id=1M8kHk4i5ZY45Ts0wDmtt9XlwFclH4uPP', artist: 'Maher Zain' },
	{ id: 1, name: 'Ya Nabi Salam Alayka(Intl)', url: 'https://drive.google.com/u/0/uc?id=1d4-83HDgt4ym1DUdvIewLQdYcCyRMDbP', artist: 'Maher Zain' },
	{ id: 2, name: 'The Chosen One', url: 'https://drive.google.com/u/0/uc?id=1ICK9AkQl88Xsorr4stktNlmJvUfbzqzd', artist: 'Maher Zain' },
	{ id: 3, name: 'Ya Nabi Salam Alayka(Arabic)', url: 'https://drive.google.com/u/0/uc?id=1cG7-2_3QxyxcUhnryC0t2Ucdbv-14y-g', artist: 'Maher Zain' },
	{ id: 4, name: 'Ramadan', url: 'https://drive.google.com/u/0/uc?id=1iKpVk2sxoNU9EdXm11P4D1Eaj2q4Qkbi', artist: 'Maher Zain' },
	{ id: 5, name: 'Lawlaka', url: 'https://drive.google.com/u/0/uc?id=1VJj5REARazWtnSojVNUbaaRVjS-yh37u', artist: 'Maher Zain' },
	{ id: 6, name: 'Huwa AlQuran', url: 'https://drive.google.com/u/0/uc?id=1kx09L1PyOqsN0DRQpmfNy9cgw9sYYGZQ', artist: 'Maher Zain' },
	{ id: 7, name: 'Baraka Allahu Lakuma', url: 'https://drive.google.com/u/0/uc?id=1ham09VY6XL2N1IE7pOEtC9Sr5oI2aGfK', artist: 'Maher Zain' },
	{ id: 8, name: 'Assubhu Bada', url: 'https://drive.google.com/u/0/uc?id=17iXrz-k1YfTpMENrKOl5QYqbQ_Yn9Fmm', artist: 'Maher Zain' },	
]

let vSamiYusuf = [
	{ id: 0, name: 'Taha', url: 'https://drive.google.com/u/0/uc?id=1Dex5OVfXqTtlRgKiL1WOJrnTzmEeF37o', artist: 'Sami Yusuf' },	
	{ id: 1, name: 'Ya Rasul Allah', url: 'https://drive.google.com/u/0/uc?id=1Sy-PxO_8G8JUDvrlX2D9rTVfR-h0-a_6', artist: 'Sami Yusuf' },
	{ id: 2, name: 'Awake (Uyan Ey Gözlerim)', url: 'https://drive.google.com/u/0/uc?id=1s04fc2JceFSKZdCXDTtkLZaOPKDRYiUV', artist: 'Sami Yusuf' },
	{ id: 3, name: 'Hasbi Rabbi', url: 'https://drive.google.com/u/0/uc?id=1b2MfILOBzef2lJdDIGYr5l3PMu0BL-nI', artist: 'Sami Yusuf' },
]

let vKirikMizrap = [
	{ id: 0, name: 'Işık Adam - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1cYXQWiqTMtyosDqwTp4b42_iAR02iSKj', artist: 'Reşit Muhtar' },
	{ id: 1, name: 'Beni Yalnız Bırakma - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1_zLEwOKSbnykTpwN7oZPBYLXtfLOPPEM', artist: 'Reşit Muhtar' },
	{ id: 2, name: 'Hiç - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1s_asVWGhgfRmhceUacH7TGLiIYPSjwKC', artist: 'Reşit Muhtar' },
	{ id: 3, name: 'Yaz Rüyaları - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1a8AWeOiF1WlI9aP4w_ccfTeJOdb_cVNZ', artist: 'Reşit Muhtar' },
	{ id: 4, name: 'Öteler - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1lH8yOtyEaf9pP_ULMYk7Yz3eoPcM_ECl', artist: 'Reşit Muhtar' },
	{ id: 5, name: 'Kalk Yiğidim - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1-xM7lCSfS745YG5ql6xwLvZfOU41iW_5', artist: 'Reşit Muhtar' },
	{ id: 6, name: 'Güneş Doğacak - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1INsxuuTZOv2eTqH2wg1du5NhIF4NUXlc', artist: 'Reşit Muhtar' },
	{ id: 7, name: 'Akıncı Türküsü - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1QvR41umTpLWB3SIwdLwMNLo5JLfpQdhE', artist: 'Reşit Muhtar' },
	{ id: 8, name: 'Sır Kapıları - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1RLgmRI0g8P-zSVglUlWBgmDlIMmT8Aah', artist: 'Reşit Muhtar' },
	{ id: 9, name: 'Gül Bahçesi - Kırık Mızrap ', url: 'https://drive.google.com/u/0/uc?id=1-eMAASXS6WgyOBvvPG6WJ_Kh0R2qbHAP', artist: 'Reşit Muhtar' },
]

let vCocukKorosu1 = [
	{ id: 0, name: 'Temiz Çocuk', url: 'https://drive.google.com/u/0/uc?id=1sqkwAebKGzokWN61CNPCuuZvxhLNg46H', artist: 'Çocuk Korosu' },
	{ id: 1, name: 'Allahım Seni Seviyorum', url: 'https://drive.google.com/u/0/uc?id=1pzWD7ZEC1i3NDseF5eGCWE9lQPAyV_rX', artist: 'Çocuk Korosu' },
	{ id: 2, name: 'Beyaz Kedicik', url: 'https://drive.google.com/u/0/uc?id=1EGR5PbwYlWYL8jOGXt2tbEQzLogSBur3', artist: 'Çocuk Korosu' },
	{ id: 3, name: 'Renk Renk Çiçekler', url: 'https://drive.google.com/u/0/uc?id=1e0L7oVzggVPQAv3gkLJ-xGDSLJAXq018', artist: 'Çocuk Korosu' },
	{ id: 4, name: 'Güzel Gökyüzü', url: 'https://drive.google.com/u/0/uc?id=1A1rTZw1bgkL-S6dKo_YhwZhnB2Qgk6aU', artist: 'Çocuk Korosu' },
	{ id: 5, name: 'İslamın Şartı Beştir', url: 'https://drive.google.com/u/0/uc?id=16z7JxRcq-zjCsZSg9q6PHjZmVLcgIeQJ', artist: 'Çocuk Korosu' },
	{ id: 6, name: 'Cömert Olmalı', url: 'https://drive.google.com/u/0/uc?id=1kU9T3_okOIc65lXQQz16bmU7zrCNUvNp', artist: 'Çocuk Korosu' },
	{ id: 7, name: 'Namazdan Önce Abdest', url: 'https://drive.google.com/u/0/uc?id=1Y8W5i4B06z0SWfy2pFUfB8Bokl3pPzGY', artist: 'Çocuk Korosu' },
	{ id: 8, name: 'Namazın Farzları', url: 'https://drive.google.com/u/0/uc?id=1BcQJVSeEQhnELioh0w38J027dH8-Gc6r', artist: 'Çocuk Korosu' },
	{ id: 9, name: 'Çok Teşekkür Ederiz', url: 'https://drive.google.com/u/0/uc?id=16o1BzULZKww3SBKMpdGjMI5-nyqwYaOZ', artist: 'Çocuk Korosu' },
	{ id: 10, name: 'Sevgili Peygamberim', url: 'https://drive.google.com/u/0/uc?id=1pIYKXqV7yoAJ_SblImnlfQNzvR6DEOyZ', artist: 'Çocuk Korosu' },
]

let vCocukKorosu2 = [
	{ id: 0, name: 'Gurbet', url: 'https://drive.google.com/u/0/uc?id=1JvKD-Db4ZeTRSHK8_wsypQVMQqGep2ad', artist: 'Çocuk Korosu' },
	{ id: 1, name: 'İşte Geldi Ramazan', url: 'https://drive.google.com/u/0/uc?id=188GIjvmFWVkk2i0Fxu1E1nZeQkM9RWlc', artist: 'Çocuk Korosu' },
	{ id: 2, name: 'Dua', url: 'https://drive.google.com/u/0/uc?id=110DFSxAVT4BtD5ASQ24v8Zn_h198iA1J', artist: 'Çocuk Korosu' },
	{ id: 3, name: 'Rabbim Sana Şükür', url: 'https://drive.google.com/u/0/uc?id=1kQJnrj774zf0A-o2HEg3HJkzXXbiyKjT', artist: 'Çocuk Korosu' },
	{ id: 4, name: 'Bak Sana Güller Derdim', url: 'https://drive.google.com/u/0/uc?id=1GzpHsgYD_NvD-6FtUZJHBZB9NXZute6R', artist: 'Çocuk Korosu' },
	{ id: 5, name: 'Kaside-i Bürde', url: 'https://drive.google.com/u/0/uc?id=1nrkvhVxGN6X6rghMa7LbQWdv8ca4dEbC', artist: 'Çocuk Korosu' },
	{ id: 6, name: 'Al Çocuğum Bu Gülü', url: 'https://drive.google.com/u/0/uc?id=17WItifyNJZZ3EZMV-fTVcL8GIk4sZLgT', artist: 'Çocuk Korosu' },
	{ id: 7, name: 'Kır Çiçeği - Çiçek İlahisi', url: 'https://drive.google.com/u/0/uc?id=16_nlZosUtZ6X6SuFJ3MTfSmVZoyvzhdf', artist: 'Çocuk Korosu' },
	{ id: 8, name: 'Word Of Allah', url: 'https://drive.google.com/u/0/uc?id=1vyVVUMSZ4A5lc3ZnXuuBeIiSntxSEBSt', artist: 'Çocuk Korosu' },
	{ id: 9, name: 'Peygamberim Can Ahmedim', url: 'https://drive.google.com/u/0/uc?id=1eljYy1ulnUOarOVlwlj_3qMnuvRfJeNb', artist: 'Çocuk Korosu' },
	{ id: 10, name: 'Sultanım', url: 'https://drive.google.com/u/0/uc?id=1hbzTX4zdU7Nv2hxPI--CLNQW3UyBke8P', artist: 'Çocuk Korosu' },
]

let vCocukKorosu3 = [
	{ id: 0, name: 'Sakın Bırakma Beni', url: 'https://drive.google.com/u/0/uc?id=19FGEtX3MLxWJ22PDUfWfUmo6X7jEV4N2', artist: 'Çocuk Korosu' },
	{ id: 1, name: 'Melek Anne', url: 'https://drive.google.com/u/0/uc?id=1EbRmGdUFTr1bBDtn1meyMLf_wZJnK76Y', artist: 'Çocuk Korosu' },
	{ id: 2, name: 'Gül Kokulu Anne', url: 'https://drive.google.com/u/0/uc?id=1oUjPWr1fx5sOSTwNv2-vPrFkOIeFhB3X', artist: 'Çocuk Korosu' },
	{ id: 3, name: 'Hasret Kaldım', url: 'https://drive.google.com/u/0/uc?id=1eawhD73cf71Nyh4Tdx8WTvGHeU0LVQUl', artist: 'Çocuk Korosu' },
	{ id: 4, name: 'Bak Sana Güller Derdim', url: 'https://drive.google.com/u/0/uc?id=1sfdoyRHIuhsXnC6UUOYe66WAF9e3q8ZF', artist: 'Çocuk Korosu' },
	{ id: 5, name: 'Rüyamsın Anne', url: 'https://drive.google.com/u/0/uc?id=170hykRTzYa1z9Pn63Wa3SSD_mSt3rQld', artist: 'Çocuk Korosu' },
]

let vCocukKorosu4 = [
	{ id: 0, name: 'Biz Dünya Çocuklarıyız', url: 'https://drive.google.com/u/0/uc?id=1Rkm6DiiW6TPApugx09LM3idOHN8bBRmA', artist: 'Çocuk Korosu' },
	{ id: 1, name: 'Yetim Kız', url: 'https://drive.google.com/u/0/uc?id=1P5a53AT6Jreo50kL5NOZr6Y5Jrm4XkqS', artist: 'Çocuk Korosu' },
	{ id: 2, name: 'Neler Vermezdim', url: 'https://drive.google.com/u/0/uc?id=1UEFMpDRk7F3YIjXLSs_8UhxhgUO2k482', artist: 'Çocuk Korosu' },
	{ id: 3, name: 'Nur Yüzlüm', url: 'https://drive.google.com/u/0/uc?id=1jBc5iXIsOrLNchjVeYXVDOH9ZXyJ6m8Z', artist: 'Çocuk Korosu' },
	{ id: 4, name: 'Efendim Benim', url: 'https://drive.google.com/u/0/uc?id=1TnnQSFfGGDG69RGtajyhCHYqussOxlRW', artist: 'Çocuk Korosu' },
	{ id: 5, name: 'Anne', url: 'https://drive.google.com/u/0/uc?id=1lfnyvnutkoJ_k6wU_fWt2quoYn1CT2pp', artist: 'Çocuk Korosu' },
	{ id: 6, name: 'Efendim', url: 'https://drive.google.com/u/0/uc?id=1LohhI26D1ciSiDnw03VP5yjzO5KozrNJ', artist: 'Çocuk Korosu' },
	{ id: 7, name: 'Doymadım Bu Oyuna', url: 'https://drive.google.com/u/0/uc?id=160D6O3f4TYb11fk_msE65za7yxTBKzwk', artist: 'Çocuk Korosu' },
	{ id: 8, name: 'Sevgili Peygamberim', url: 'https://drive.google.com/u/0/uc?id=1LfL_U3474acjgKnUd3oHbtuD4DW4yLrA', artist: 'Çocuk Korosu' },
	{ id: 9, name: 'Kadem Bastım', url: 'https://drive.google.com/u/0/uc?id=1lo4OmzSdKn52hE4v2D51qtNOPtSpqnO-', artist: 'Çocuk Korosu' },
]

let vCocukKorosu5 = [
	{ id: 0, name: 'Rüyamda Sizi Gördüm', url: 'https://drive.google.com/u/0/uc?id=14aru5knpiugOXH8DmYrTHFWPYytpTXZx', artist: 'Çocuk Korosu' },
	{ id: 1, name: 'Nerdesin Öğretmenim', url: 'https://drive.google.com/u/0/uc?id=1n5PIqrXqXdRF4VqfSWDGScjDZ9Ujg-v5', artist: 'Çocuk Korosu' },
	{ id: 2, name: 'Güzel Adın', url: 'https://drive.google.com/u/0/uc?id=1WPnhICu5fmXad7F4I9iopXGNtUV-mUoA', artist: 'Çocuk Korosu' },
	{ id: 3, name: 'Değerini Bilmek Lazım', url: 'https://drive.google.com/u/0/uc?id=1GxG_4UGueSNbS7tN5AOTjVcQ3TyjyT2S', artist: 'Çocuk Korosu' },
	{ id: 4, name: 'Gül Efendim', url: 'https://drive.google.com/u/0/uc?id=18b_RBjVcIEReskMBdNe7z9fBCVRujjkZ', artist: 'Çocuk Korosu' },
	{ id: 5, name: 'Samanyolu Yıldızları', url: 'https://drive.google.com/u/0/uc?id=1V1QY11JpuWksKkrCD2I2a7dTIsfKG_sr', artist: 'Çocuk Korosu' },
	{ id: 6, name: 'Can Ahmet', url: 'https://drive.google.com/u/0/uc?id=1jXkKoHc7TD5dL3_hfkXm7a8jlzD2fFIo', artist: 'Çocuk Korosu' },
	{ id: 7, name: 'Esma', url: 'https://drive.google.com/u/0/uc?id=1b3nc9ETjFziqUj8ji5T1WFCPVod01J7t', artist: 'Çocuk Korosu' },
	{ id: 8, name: 'Ceplerimi Yıldızla Doldur', url: 'https://drive.google.com/u/0/uc?id=1YiB29ObsD9Z_KBTs2ASTWjCqae-IJ9jn', artist: 'Çocuk Korosu' },
	{ id: 9, name: 'Bahar Geldi', url: 'https://drive.google.com/u/0/uc?id=1HOUs8ZE_FnysNEYWAjccykCs-OvYJRst', artist: 'Çocuk Korosu' },
	{ id: 10, name: 'Gül Efendim (Akustik)', url: 'https://drive.google.com/u/0/uc?id=1xfoBkRo9XYGxfW6axaDoTSNWx9Kb-Yvz', artist: 'Çocuk Korosu' },
]

let vMehmetEminAyIlahiler = [
	{ id: 0, name: 'Allahu Allah', url: 'https://drive.google.com/u/0/uc?id=1B9n89akypKYu1HY11wqy_mwzGapQ_914', artist: 'Mehmet Emin Ay' },
	{ id: 1, name: 'Çağırayım Mevlam Seni', url: 'https://drive.google.com/u/0/uc?id=1WmH6fkCI4Moepy6tL9bg0snr5ASb0LhK', artist: 'Mehmet Emin Ay' },
	{ id: 2, name: 'Gönül Nur-i Cemalinden', url: 'https://drive.google.com/u/0/uc?id=1e2BXGb7ddvYOETFd_e6ZDmuIZuPxtv5A', artist: 'Mehmet Emin Ay' },
	{ id: 3, name: 'Gözlerim', url: 'https://drive.google.com/u/0/uc?id=1dM5gHkkMQ39L5HnkZL_2DrQg7jt65G_G', artist: 'Mehmet Emin Ay' },
	{ id: 4, name: 'İnilteyari (Münacaat)', url: 'https://drive.google.com/u/0/uc?id=16ZHg4QkWvsh8Q5d7jg9rFWDcKKjD3iVb', artist: 'Mehmet Emin Ay' },
	{ id: 5, name: 'Kan Tutar', url: 'https://drive.google.com/u/0/uc?id=1xG-mp1VThaTa2tOwSht77AwgE6U8FqbQ', artist: 'Mehmet Emin Ay' },
	{ id: 6, name: 'Kararım Kalmaz Allahım', url: 'https://drive.google.com/u/0/uc?id=1ZflfoiNf5RCgPSOEywLcTFC5fNjo3lB2', artist: 'Mehmet Emin Ay' },
	{ id: 7, name: 'Mevlam Sana Ersem Diye', url: 'https://drive.google.com/u/0/uc?id=1Q8X5E0B4xdxWuWD7KAOfWV9bHZ3rYRan', artist: 'Mehmet Emin Ay' },
	{ id: 8, name: 'Nefse Uyup', url: 'https://drive.google.com/u/0/uc?id=1GSc0_evtYcIshDN2g72nHr4LP_3AKMm3', artist: 'Mehmet Emin Ay' },
	{ id: 9, name: 'Taleal Bedru Aleyna', url: 'https://drive.google.com/u/0/uc?id=1zFcLuKkjZysz9bJERgEYGnb5s2iXTaG0', artist: 'Mehmet Emin Ay' },
	{ id: 10, name: 'Yağmur', url: 'https://drive.google.com/u/0/uc?id=1MgX0j5-7ahjsgj-4smiM4yojRuH10KeC', artist: 'Mehmet Emin Ay' },
	{ id: 11, name: 'Meftun Olarak', url: 'https://drive.google.com/u/0/uc?id=1KMltmtksfefHhSgytZr6Eabw3dqenM8f', artist: 'Mehmet Emin Ay' },
]

let vIngilizceIlahiler1 = vMaherZain.concat(vSamiYusuf)
let vCocukKorolari = vYamanlarCocukKorosu.concat(vMinikDualarGrubu1).concat(vCocukKorosu1).concat(vCocukKorosu2).concat(vCocukKorosu3).concat(vCocukKorosu4).concat(vCocukKorosu5)

let vYesilOkulDinlemeListesi1 = vYamanlarCocukKorosu.concat(vErtugrulErkisi1)
	.concat(vEsmaiHusna1)
	.concat(vMinikDualarGrubu1)
	.concat(vBarisManco1)
	.concat(vYamanlarMuzikGrubu1)
	.concat(vTurkceOlimpiyatNaatGeceleri1)
	.concat(vMaherZain)
	.concat(vSamiYusuf)
	.concat(vKirikMizrap)
	.concat(vCocukKorosu1)
	.concat(vCocukKorosu2)
	.concat(vCocukKorosu3)
	.concat(vCocukKorosu4)
	.concat(vCocukKorosu5)
	.concat(vMehmetEminAyIlahiler)

let PL_YesilOkulVideoPlaylists1 = [
	{ name: 'YesilOkulDinlemeListesi1', title: '#DINLEME LISTESI 1#', slug: 'yesil-okul-dinleme-listesi-1', videos: vYesilOkulDinlemeListesi1, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'dualarPlaylist', title: 'DUA EZBER LİSTESİ 1', slug: 'dua-ezber-1', videos: vDuaEzber1, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'dualarFatihCollakPlaylist', title: 'DUA EZBER LİSTESİ 2', slug: 'dua-ezber-2',  videos: vDuaEzber2, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'dualarDavudKayaPlaylist', title: 'DUA EZBER LİSTESİ 3', slug: 'dua-ezber-3', videos: vDuaEzber3, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'dualarMustafaYigitTekrarliPlaylist', title: 'DUA EZBER TEKRARLI 1', slug: 'dua-ezber-tekrarli-1', videos: vDuaEzberTekrarli1, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'kisaSurelerDavutKaya', title: 'KISA SURELER 1', slug: 'kisa-sureler-ezber-1', videos: vKisaSureler, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'kisaSurelerMustafaYigitTekrarli', title: 'KISA SURELER - TEKRARLI 1', slug: 'kisa-sureler-ezber-tekrarli-1', videos:vKisaSurelerTekrarli1, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },

	{ name: 'DuhaOncesiKisaSurelerTekOkuma1', title: 'KISA SURELER (DUHA ONCESI) - 1', slug: 'kisa-sureler-duha-oncesi-1', videos:vDuhaOncesiKisaSurelerTekOkuma1, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'DuhaOncesiKisaSurelerTekOkuma2', title: 'KISA SURELER (DUHA ONCESI) - 2', slug: 'kisa-sureler-duha-oncesi-2', videos:vDuhaOncesiKisaSurelerTekOkuma2, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'DuhaOncesiKisaSurelerTekOkuma3', title: 'KISA SURELER (DUHA ONCESI) - 3', slug: 'kisa-sureler-duha-oncesi-3', videos:vDuhaOncesiKisaSurelerTekOkuma3, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'DuhaOncesiKisaSurelerTekOkuma4', title: 'KISA SURELER (DUHA ONCESI) - 4', slug: 'kisa-sureler-duha-oncesi-4', videos:vDuhaOncesiKisaSurelerTekOkuma4, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'DuhaOncesiKisaSurelerTekOkuma5', title: 'KISA SURELER (DUHA ONCESI) - 5', slug: 'kisa-sureler-duha-oncesi-5', videos:vDuhaOncesiKisaSurelerTekOkuma5, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },

	{ name: 'DuhaOncesiKisaSurelerTekrarli1', title: 'KISA SURELER (DUHA ONCESI) - TEKRARLI 1', slug: 'kisa-sureler-duha-oncesi-tekrarli-1', videos:vDuhaOncesiKisaSurelerTekrarli1, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'DuhaOncesiKisaSurelerTekrarli2', title: 'KISA SURELER (DUHA ONCESI) - TEKRARLI 2', slug: 'kisa-sureler-duha-oncesi-tekrarli-2', videos:vDuhaOncesiKisaSurelerTekrarli2, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	{ name: 'DuhaOncesiKisaSurelerTekrarli3', title: 'KISA SURELER (DUHA ONCESI) - TEKRARLI 3', slug: 'kisa-sureler-duha-oncesi-tekrarli-3', videos:vDuhaOncesiKisaSurelerTekrarli3, shuffle: false, list: true, playlistCategory: 'cocuk-kuran-ezber' },
	
	
	{ name: 'cevsenIrmakTV', title: 'CEVSEN 1', slug: 'cevsen-1', videos: vCevsen1, shuffle: false, list: true, playlistCategory: 'cevsen' },
	{ name: 'ErtugrulErkisi1', title: 'E. ERKISI 1', slug: 'ertugrul-erkisi-1', videos: vErtugrulErkisi1, shuffle: true, list: false, playlistCategory: 'cocuk-muzik' },
	{ name: 'EsmaiHusna1', title: 'ESMA-İ HÜSNA 1', slug: 'esmai-husna-1', videos: vEsmaiHusna1, shuffle: false, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'MinikDualarGrubu1', title: 'MİNİK DUALAR 1', slug: 'minik-dualar-grubu-1', videos: vMinikDualarGrubu1, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'YamanlarCocukKorosu', title: 'YAMANLAR COCUK KOROSU', slug: 'muzik-yamanlar-cocuk-korosu', videos: vYamanlarCocukKorosu, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'BarisManco1', title: 'BARIŞ MANÇO', slug: 'baris-manco-1', videos: vBarisManco1, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'NamazTesbihatlari1', title: 'TESBİHAT', slug: 'namaz-tesbihatlari-1', videos: vNamazTesbihatlari1, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'YamanlarMuzikGrubu1', title: 'YAMANLAR MÜZİK GRUBU', slug: 'yamanlar-muzik-grubu', videos: vYamanlarMuzikGrubu1, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'TurkceOlimpiyatNaatGeceleri1', title: 'NAATLAR 1', slug: 'naatlar-1', videos: vTurkceOlimpiyatNaatGeceleri1, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'IngilizceIlahiler1', title: 'İNGİLİZCE İLAHİLER 1', slug: 'ingilizce-ilahiler-1', videos: vIngilizceIlahiler1, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'KirikMizrap', title: 'KIRIK MIZRAP', slug: 'kirik-mizrap', videos: vKirikMizrap, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'İlahiler1', title: 'İLAHİLER 1', slug: 'ilahiler-1', videos: vMehmetEminAyIlahiler, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },
	{ name: 'CocukKorosu', title: 'ÇOCUK KOROLARI', slug: 'cocuk-korosu', videos: vCocukKorolari, shuffle: true, list: true, playlistCategory: 'cocuk-muzik' },	
]

export {
	PL_YesilOkulVideoPlaylists1
}
