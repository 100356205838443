import firebase from "firebase"

const firebaseApp = firebase.initializeApp({
	apiKey: "AIzaSyCYPCbHL_vDA2bbMIbKu5tn9Nvp5Yk-Sz4",
    authDomain: "vidpoi-a28f4.firebaseapp.com",
    databaseURL: "https://vidpoi-a28f4.firebaseio.com",
    projectId: "vidpoi-a28f4",
    storageBucket: "vidpoi-a28f4.appspot.com",
    messagingSenderId: "934937325507",
    appId: "1:934937325507:web:2ade3b90392f3853845776",
    measurementId: "G-FK51LJ9V2C"
})

const firestore = firebaseApp.firestore()

export { firestore }
